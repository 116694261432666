import React, { useEffect, useState } from 'react';
import {  json, useNavigate, useParams } from 'react-router-dom';
import { KayliColorAPI } from '../../apis/KayliColorAPI';
import { Ticket } from '../../models/Ticket';
import { TicketComment } from '../../models/TicketComment';
import { CommentType } from '../../models/CommentType';
import { ColorAnalysisRequestState } from '../../models/ColorAnalysisRequestState';
import { TicketResult } from '../../models/TicketResult';
import { Button } from 'react-bootstrap';
import './AdminRequestManage.css';
import { ImageEditorComponent } from '@syncfusion/ej2-react-image-editor';
import { registerLicense } from '@syncfusion/ej2-base'
import { ColorFramedPortrait } from '../../components/ColorFramedPortrait';
import { ColorSeasons } from '../../models/ColorSeasons';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { ColorFrames } from '../../models/ColorFrames';
import { ColorFrame } from '../../models/ColorFrame';
import { Blurb, BlurbType } from '../../models/Blurb';
import { InspoImage, InspoImageType } from '../../models/InspoImage';
import Select from 'react-select';
import { Footer } from '../../components/Footer';
import { toZonedTime } from 'date-fns-tz';
import { Utils } from '../../apis/Utils';
import Loading from '../../components/Loading';
import { CropSelectionShape } from '../../models/CropSelectionShape';
import imageCompression from 'browser-image-compression';
import { handleFileUpload } from '../../lib/fileUploader';

registerLicense("Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXhfcnVQRWZfV0NxV0M=");

const AdminRequestManage = () => {
  let navigate = useNavigate(); 
  let { email } = useParams();
  email = email?.toLowerCase();
  const colorFrames = ColorFrames.getAllColorFrames();
  const seasonsForMultiDropDown = Object.values(ColorSeasons).map((s) => { return { value: s, label: s } } );
  const seasonsForDropDown = Object.values(ColorSeasons);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [cropping, setCropping] = useState<number>(0);
  const [allInspoImages, setAllInspoImages] = useState<InspoImage[]>([]);
  const [relevantHairInspo, setRelevantHairInspo] = useState<InspoImage[]>([]);
  const [relevantMakeupInspo, setRelevantMakeupInspo] = useState<InspoImage[]>([]);
  const [choosenHairInspo, setChoosenHairInspo] = useState<InspoImage[]>([]);
  const [choosenMakeupInspo, setChoosenMakeupInspo] = useState<InspoImage[]>([]);
  const [allBlurbs, setAllBlurbs] = useState<Blurb[]>([]);
  const [relevantIntroBlurbs, setRelevantIntroBlurbs] = useState<Blurb[]>([]);
  const [relevantHairBlurbs, setRelevantHairBlurbs] = useState<Blurb[]>([]);
  const [relevantMakeupBlurbs, setRelevantMakeupBlurbs] = useState<Blurb[]>([]);
  const [relevantSisterBlurbs, setRelevantSisterBlurbs] = useState<Blurb[]>([]);
  const [relevantOppositeBlurbs, setRelevantOppositeBlurbs] = useState<Blurb[]>([]);
  const [seasonLeft, setSeasonLeft] = useState<ColorSeasons>(ColorSeasons.CoolSummer);
  const [colorFrameLeft, setColorFrameLeft] = useState<ColorFrame>(colorFrames.find((f) => f.season === ColorSeasons.CoolSummer)!);
  const [colorFrameOptionsLeft, setColorFrameOptionsLeft] = useState<ColorFrame[]>(colorFrames.filter((f) => f.season === ColorSeasons.CoolSummer)!);
  const [seasonRight, setSeasonRight] = useState<ColorSeasons>(ColorSeasons.DeepWinter);
  const [colorFrameRight, setColorFrameRight] = useState<ColorFrame>(colorFrames.find((f) => f.season === ColorSeasons.DeepWinter)!);
  const [colorFrameOptionsRight, setColorFrameOptionsRight] = useState<ColorFrame[]>(colorFrames.filter((f) => f.season === ColorSeasons.DeepWinter)!);
  const [result, setResult] = useState<TicketResult>();
  const [ticket, setTicket] = useState<Ticket>();
  const [path, setPath] = useState<string>("M 50, 0 a 100,100 0 1,0 1,0");
  const [comment, setComment] = useState<TicketComment>({ body: '', email: email!, type: CommentType.Analyzer, createdDate: new Date()});
  const [introduction, setIntroduction] = useState<string>(result?.introduction || '');
  const [hairExplanation, setHairExplanation] = useState<string>(result?.hairInspoExplanation || '');
  const [makeupExplanation, setMakeupExplanation] = useState<string>(result?.makeupInspoExplanation || '');
  const [newBlurbName, setNewBlurbName] = useState<string>('');
  const [newHairBlurbName, setNewHairBlurbName] = useState<string>('');
  const [newMakeupBlurbName, setNewMakeupBlurbName] = useState<string>('');
  const [selectedSisterSeasons, setSelectedSisterSeasons] = useState<ColorSeasons[]>([]);
  const [noBlurbSisterSeasons, setNoBlurbSisterSeasons] = useState<ColorSeasons[]>([]);
  const [selectedOppositeSeasons, setSelectedOppositeSeasons] = useState<ColorSeasons[]>([]);
  const [noBlurbOppositeSeasons, setNoBlurbOppositeSeasons] = useState<ColorSeasons[]>([]);
  const [selectedContextPhoto, setSelectedContextPhoto] = useState<string>('');
  const [selectedContextPhotoNumber, setSelectedContextPhotoNumber] = useState<number>(4);
  const [croppedImageUrl, setCroppedImageUrl] = useState<string>("");
  const [cropSelectionShape, setCropSelectionShape] = useState<CropSelectionShape>();

  const [loadedResult, setLoadedResult] = useState<boolean>(false);
  const [loadedTicket, setLoadedTicket] = useState<boolean>(false);
  const [loadedInspos, setLoadedInspos] = useState<boolean>(false);
  const [loadedBlurbs, setLoadedBlurbs] = useState<boolean>(false);
  const [loadedAll, setLoadedAll] = useState<boolean>(false);

  let editingImage: ImageEditorComponent | null;

  useEffect(() => {
    if(result?.cropPath) {
      setPath(result?.cropPath);
    }
    if(result?.result) {
      setRelevantHairInspo(allInspoImages?.filter((b) => b.season === result?.result && b.type === InspoImageType.Hair));
      setRelevantMakeupInspo(allInspoImages?.filter((b) => b.season === result?.result && b.type === InspoImageType.Makeup));
      setRelevantIntroBlurbs(allBlurbs?.filter((b) => b.primarySeason === result?.result && b.type === BlurbType.Introduction));
      setRelevantHairBlurbs(allBlurbs?.filter((b) => b.primarySeason === result?.result && b.type === BlurbType.Hair));
      setRelevantMakeupBlurbs(allBlurbs?.filter((b) => b.primarySeason === result?.result && b.type === BlurbType.Makeup));
      if(result?.hairInspoUrls && allInspoImages?.length > 0) {
        setChoosenHairInspo(allInspoImages.filter(i => result?.hairInspoUrls?.filter(ri => ri === i.url).length > 0));
      }
      if(result?.makeUpInspoUrls && allInspoImages?.length > 0) {
        setChoosenMakeupInspo(allInspoImages.filter(i => result?.makeUpInspoUrls?.filter(ri => ri === i.url).length > 0));
      }
      setSelectedSisterSeasons(result.sisterSeasons)
      setSelectedOppositeSeasons(result.oppositeSeasons)
      if(loadedBlurbs)
      {
        setNoBlurbSisterSeasons(result.sisterSeasons.filter((s) => !allBlurbs.find((b) => b.primarySeason === result.result && b.type === BlurbType.SisterSeasons && b.otherSeason === s)))
        setNoBlurbOppositeSeasons(result.oppositeSeasons.filter((s) => !allBlurbs.find((b) => b.primarySeason === result.result && b.type === BlurbType.OppositeSeasons && b.otherSeason === s)))
      }
    }
    console.log('Is Infinite Loop?');
    setCroppedImageUrl(result?.croppedImageUrl || "");

    if(result?.introduction) {
      setIntroduction(result?.introduction);
    }
    if(result?.hairInspoExplanation) {
      setHairExplanation(result?.hairInspoExplanation);
    }
    if(result?.makeupInspoExplanation) {
      setMakeupExplanation(result?.makeupInspoExplanation);
    }
    if(loadedResult && loadedTicket && loadedInspos && loadedBlurbs)
    {
      setLoadedAll(true);
    }
  }, [ result, allBlurbs, allInspoImages ]);

  useEffect(() => {
    if(result && loadedAll) {
      result.hairInspoUrls = choosenHairInspo.map(i => i.url);
      result.makeUpInspoUrls = choosenMakeupInspo.map(i => i.url);
      result.sisterSeasons = selectedSisterSeasons;
      result.oppositeSeasons = selectedOppositeSeasons;
      setResult(result);
    }
    console.log('Is Infinite Loop 2?');
  }, [ choosenHairInspo, choosenMakeupInspo, selectedSisterSeasons, selectedOppositeSeasons ]);  

  useEffect(() => {
    KayliColorAPI.getTicket(email!)
      .then((response)=>{
        setSelectedContextPhoto(ticket?.requestImageUrls?.at(0) ? ticket!.requestImageUrls!.at(0)! : '');
        setTicket(response.data);
        setLoadedTicket(true);
      })
      .catch(function (error) {
        setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
        console.log(error.response ? error.response : error.request);
      })

    KayliColorAPI.getResult(email!)
      .then((response)=>{
        setResult(response.data);
        setLoadedResult(true);
      })
      .catch(function (error) {
        setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
        console.log(error.response ? error.response : error.request);
      })
  }, [ ]);

  useEffect(() => {
    KayliColorAPI.getAllBlurbs()
      .then((response)=>{
        setAllBlurbs(response.data);
        setLoadedBlurbs(true);
      })
      .catch(function (error) {
        setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
        console.log(error.response ? error.response : error.request);
      })

    KayliColorAPI.getAllInspoImages()
      .then((response)=>{
        setAllInspoImages(response.data);
        setLoadedInspos(true);
      })
      .catch(function (error) {
        setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
        console.log(error.response ? error.response : error.request);
      })
  }, [ ]);

  const updateResult = (newResult: TicketResult) => {
    setErrorMessage('');
    setLoading(true);

    let r = newResult ? newResult : result;
    
    KayliColorAPI.updateResult(r!)
        .then((response)=>{
            setResult(response.data);
            //TODO Success toast
        })
        .catch(function (error) {
            setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
            console.log(error.response ? error.response : error.request);
        })
        .finally( () => {
            setLoading(false);
        });
  }

  const uploadInspoImage = (event: any, type: InspoImageType) => {
    let newInspos: InspoImage[] = [];

    for(var i = 0;i<event.target.files.length;i++) {
      KayliColorAPI.uploadInspoImage(result!.result, type, event.target.files[i])
        .then((response)=>{
          let newInspo: InspoImage = {
            season: result!.result,
            type: type,
            url: response.data
          };
          if(type === InspoImageType.Hair) {
            newInspos.push(newInspo);
            if(newInspos.length === event.target.files.length) {
              setRelevantHairInspo([...relevantHairInspo, ...newInspos]);
            }
          }
          else {
            newInspos.push(newInspo);
            if(newInspos.length === event.target.files.length) {
              setRelevantMakeupInspo([...relevantMakeupInspo, ...newInspos]);
            }
          }
          setAllInspoImages([...allInspoImages, newInspo]);
        })
        .catch(function (error) {
            setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
            console.log(error.response ? error.response : error.request);
        })
        .finally( () => {
            setLoading(false);
        });
    }
  }

  const addComment = (e: any) => {
    e.preventDefault();
    setErrorMessage('');
    setLoading(true);
    
    KayliColorAPI.createComment(comment)
        .then((response)=>{
            ticket!.comments.push(comment);
            setTicket(ticket);
            setComment({ body: '', email: email!, type: CommentType.Analyzer, createdDate: new Date()});
        })
        .catch(function (error) {
            setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
            console.log(error.response ? error.response : error.request);
        })
        .finally( () => {
            setLoading(false);
        });
  }

  const submitTicket = () => {
    setErrorMessage('');
    setLoading(true);

    KayliColorAPI.publishResult(email!)
        .then((response)=>{
            setTicket(response.data);
            if(response?.data?.status === ColorAnalysisRequestState.ResultsDelivered) {
              navigate('/admin')
            }
            else {
                setErrorMessage(`Submission failed. Ticket is not in delivered status. ${KayliColorAPI.tryLater}`);
                console.log("Incorect state after submitting results:" + JSON.stringify(ticket));
            }
        })
        .catch(function (error) {
            setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
            console.log(error.response ? error.response : error.request);
        })
        .finally( () => {
            setLoading(false);
        });
  }

  const needsMoreInfo = () => {
    setErrorMessage('');
    setLoading(true);

    KayliColorAPI.needsMoreInfo(email!)
        .then((response)=>{
            setTicket(response.data);
        })
        .catch(function (error) {
            setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
            console.log(error.response ? error.response : error.request);
        })
        .finally( () => {
            setLoading(false);
        });
  }

  const readyToSubmit = () => {
    return result?.croppedImageUrl && result.result && result.cropPath && result.hairInspoUrls && result.hairInspoUrls.length > 0
      && result.sisterSeasons && result.sisterSeasons.length > 0
      && result.oppositeSeasons && result.oppositeSeasons.length > 0
      && noBlurbOppositeSeasons.length === 0 && noBlurbSisterSeasons.length === 0;
  }

  const saveNewBlurb = (type: BlurbType, name: string, content: string, otherSeason?: ColorSeasons) => {
    let blurb = ({
      primarySeason: result!.result,
      otherSeason,
      type,
      subType: name,
      content
    } as Blurb)

    KayliColorAPI.createBlurb(blurb!)
      .then((response)=>{
        if(type === BlurbType.Introduction) {
          setRelevantIntroBlurbs([...relevantIntroBlurbs!, blurb]);
        }
        if(type === BlurbType.Hair) {
          setRelevantHairBlurbs([...relevantHairBlurbs!, blurb]);
        }
        if(type === BlurbType.Makeup) {
          setRelevantMakeupBlurbs([...relevantMakeupBlurbs!, blurb]);
        }
        else if(type === BlurbType.SisterSeasons) {
          setRelevantSisterBlurbs([...relevantSisterBlurbs!, blurb]);
        }
        else if(type === BlurbType.OppositeSeasons) {
          setRelevantOppositeBlurbs([...relevantOppositeBlurbs!, blurb]);
        }
        else {
          console.log('Tried to create unknown blurb type: ' + type);
        }
        setNewBlurbName('');
        setNewHairBlurbName('');
        setNewMakeupBlurbName('');
        setAllBlurbs([...allBlurbs, blurb]);
      })
      .catch(function (error) {
        setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
        console.log(error.response ? error.response : error.request);
      })
      .finally( () => {
        setLoading(false);
      });
  }

  const setIntroductionAndResult = (value: string) => {
    setIntroduction(value);
    result!.introduction = value;
    setResult(result);
  }

  const setMakeupAndResult = (value: string) => {
    setMakeupExplanation(value);
    result!.makeupInspoExplanation = value;
    setResult(result);
  }

  const setHairAndResult = (value: string) => {
    setHairExplanation(value);
    result!.hairInspoExplanation = value;
    setResult(result);
  }

  const openCropper = (num: number) => {
    if(num === 3) 
    {
      if(cropping === -1) { setCropping(3) }
      else { setCropping(-1) }
    }
    else
    {
      setCropping(num);
    }
  }

  const startFaceSelection = () => {
    let imageUrlToEdit = ""

    if(cropping === 1) { imageUrlToEdit = ticket!.mainImageUrl! }
    else if(cropping === 2) { imageUrlToEdit = ticket!.mainImageUrlAlt! }
    else if(cropping === 3) { imageUrlToEdit = result!.croppedImageUrl! }
    else { imageUrlToEdit = ticket!.requestImageUrls[cropping-4] }

    if(isEmptyOrSpaces(result?.croppedImageUrl) || result?.croppedImageUrl !== imageUrlToEdit) {
      let unmodifiedImageUrl = imageUrlToEdit;
      console.log("setting unmodifiedImageUrl");
      console.log(unmodifiedImageUrl);
      let updatedResult = {...result!, 
                            croppedImageUrl: (unmodifiedImageUrl as string)
                          };
      setCroppedImageUrl(unmodifiedImageUrl!);
      setResult(updatedResult);
      updateResult(updatedResult);
    }
  }

  const showFaceSelector = () => {
    let dimension: any = editingImage!.getImageDimension();
    editingImage!.drawEllipse(dimension.x, dimension.y, dimension.width*0.7, dimension.height*0.7);
    editingImage!.selectShape('shape_0');
  }

  const shapeChange = (event: any) => {
    let dimension: any = editingImage!.getImageDimension();
    console.log(JSON.stringify(event));
    console.log("Dimension! :: " + JSON.stringify(dimension));
    let multiplierY = result!.croppedImageHeightPixels! / dimension.height;
    let multiplierX = result!.croppedImageWidthPixels! / dimension.width;
    let xRadius = event?.currentShapeSettings?.radiusX;
    let yRadius = event?.currentShapeSettings?.radiusY;
    let startX = event?.currentShapeSettings?.startX - dimension.x + (event?.currentShapeSettings?.radiusX);
    let startY = event?.currentShapeSettings?.startY - dimension.y;
    let p = `M ${startX*multiplierX}, ${startY*multiplierY} a ${xRadius*multiplierX},${yRadius*multiplierY} 0 1,0 1,0`;
    console.log(p);
    setPath(p);

    let shape = {
      startX: event?.currentShapeSettings?.startX,
      startY: startY = event?.currentShapeSettings?.startY,
      radiusX: event?.currentShapeSettings?.radiusX,
      radiusY: event?.currentShapeSettings?.radiusY
    } as any;
    setCropSelectionShape(shape);
    console.log("Shape! :: " + JSON.stringify(shape));
  }

  const logCropper = (event: any) => {
    let dimension: any = editingImage!.getImageDimension();
    console.log("Log Cropper");
    console.log(JSON.stringify(event));
    console.log("Dimension! :: " + JSON.stringify(dimension));
  }

  const autoCrop = (event: any) => {
    console.log("cropSelectionShape! :: " + JSON.stringify(cropSelectionShape));

    let dimension: any = editingImage!.getImageDimension();
    console.log("Dimension2! :: " + JSON.stringify(dimension));

    editingImage!.deleteShape('shape_1');
    editingImage!.select("3:4");

    let dimensionAfterCrop: any = editingImage!.getImageDimension();
    console.log("dimensionAfterCrop! :: " + JSON.stringify(dimension));
    let multX = dimensionAfterCrop.width / dimension.width;
    let multY = dimensionAfterCrop.height / dimension.height;

    let cropWidth = (cropSelectionShape!.radiusX*2)*1.493*multX;
    let cropHeight = cropWidth*1.333;

    let cropStartX = ((cropSelectionShape!.startX - dimension.x) * multX) - (0.165*cropWidth) + dimensionAfterCrop.x;
    let cropStartY = ((cropSelectionShape!.startY - dimension.y) * multY) - (0.15*cropHeight) + dimensionAfterCrop.y;
    editingImage!.select("3:4", cropStartX, cropStartY, cropWidth, cropHeight);

    editingImage!.crop();

    onCropperSave(event);
    let dimFinal: any = editingImage!.getImageDimension();
    console.log("dimFinal! :: " + JSON.stringify(dimension));

    let imagePixelsPerScreenPixelXBefore = result!.croppedImageWidthPixels! / dimension.width;
    let imagePixelsInCroppedX = (cropWidth/dimensionAfterCrop.width) * result!.croppedImageWidthPixels!;
    let cutoutMultX = result!.croppedImageWidthPixels! / imagePixelsInCroppedX;

    let imagePixelsPerScreenPixelYBefore = result!.croppedImageHeightPixels! / dimensionAfterCrop.height;
    let imagePixelsInCroppedY = (cropHeight/dimensionAfterCrop.height) * result!.croppedImageHeightPixels!;
    let cutoutMultY = result!.croppedImageHeightPixels! / imagePixelsInCroppedY;

    let multiplierX = dimFinal.width / dimension.width;
    let multiplierY = dimFinal.height / dimension.height;

    let cutoutRadiusX = cropSelectionShape!.radiusX * multiplierX * cutoutMultX;
    let cutoutRadiusY = cropSelectionShape!.radiusY * multiplierY * cutoutMultY;

    let cutoutStartX = dimFinal.x + (dimFinal.width/2) - cutoutRadiusX;
    let cutoutStartY = dimFinal.y + (dimFinal.height/2) - cutoutRadiusY;

    editingImage!.drawEllipse(cutoutStartX, cutoutStartY, cutoutRadiusX, cutoutRadiusY, undefined, undefined, undefined, undefined, true);

    shapeChange({currentShapeSettings: 
      { 
        startX: cutoutStartX,
        startY: cutoutStartY,
        radiusX: cutoutRadiusX,
        radiusY: cutoutRadiusY
      }})
  }

  const onCropperSave = (event: any) => {
    setErrorMessage('');
    setLoading(true);
    setCropping(3);

    //let startTime = new Date()
    //console.log("Time 1: " + (startTime.getTime() - new Date().getTime()).toString());
    
    var imageData = editingImage!.getImageData();

    const canvas = document.createElement('canvas');
    canvas.width = imageData.width;
    canvas.height = imageData.height;
    const context = canvas.getContext('2d');
    context!.putImageData(imageData, 0, 0);
    let base64String = canvas.toDataURL();

    var arr = base64String.split(','),
    mime = arr[0]!.match(/:(.*?);/)![1]!,
    bstr = atob(arr[arr.length - 1]), 
    n = bstr.length, 
    u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    let file = new File([u8arr], "filename.jpg", {type:mime});

    handleFileUpload(file, '', setErrorMessage)
      .then((imageUrl)=>{
        console.log("Setting setCroppedImageUrl");
        console.log(imageUrl);
        let updatedResult = {...result!, croppedImageUrl: (imageUrl as string)};
        setResult(updatedResult);
        updateResult(updatedResult);
        setCroppedImageUrl(imageUrl || "");
      })
      .finally( () => {
          setLoading(false);
      });
  }

  const imageEditorReady = () => {
    if(cropping === 1) {
      (editingImage as any).open(ticket?.mainImageUrl);
    } else if(cropping === 2) {
      (editingImage as any).open(ticket?.mainImageUrlAlt);
    }
    else if(cropping === 3) {
      (editingImage as any).open(result?.croppedImageUrl);
    }
    else if(cropping === 3) {
      (editingImage as any).open(result?.croppedImageUrl);
    }
    else {
      (editingImage as any).open(ticket!.requestImageUrls[cropping-4]);
    }
    console.log('imageEditorReady!');
    startFaceSelection();
  }

  const onSelectionSave = (event: any) => {
    setCropping(0);
    let updatedResult = {...result!, cropPath: (path as string)};
    setResult(updatedResult);
    updateResult(updatedResult);
  }

  const decideResult = (decision: ColorSeasons) => {
    result!.result = decision;
    let updatedResult = {
      ...result!, 
      result: decision,
      introduction: '',
      hairExplanation: '',
      makeupExplanation: '',
      hairInspoUrls: [],
      makeUpInspoUrls: [],
      sisterSeasons: [],
      oppositeSeasons: []
    };
    setResult(updatedResult);
    updateResult(updatedResult);
  }

  const setSeasonAndOptions = (event: any, isLeft: boolean) => {
    if(isLeft) {
      setSeasonLeft(event.value);
      setColorFrameLeft(colorFrames.find((f) => f.season === event.value)!);
      setColorFrameOptionsLeft(colorFrames.filter((f) => f.season === event.value)!)
    }
    else {
      setSeasonRight(event.value);
      setColorFrameRight(colorFrames.find((f) => f.season === event.value)!);
      setColorFrameOptionsRight(colorFrames.filter((f) => f.season === event.value)!)
    }
  }

  const selectInspo = (inspo: InspoImage) => {
    if(inspo.type === InspoImageType.Hair) {
      if(!choosenHairInspo.find(i => i.url === inspo.url)) {
        setChoosenHairInspo([...choosenHairInspo, inspo]);
      }
    }
    else {
      if(!choosenMakeupInspo.find(i => i.url === inspo.url)) {
        setChoosenMakeupInspo([...choosenMakeupInspo, inspo]);
      }
    }

    result!.hairInspoUrls = choosenHairInspo.map(i => i.url);
    result!.makeUpInspoUrls = choosenMakeupInspo.map(i => i.url);
    setResult(result);
  }

  const deselectInspo = (inspo: InspoImage, index: number) => {
    let newInspoArray: InspoImage[] = [];
    if(inspo.type === InspoImageType.Hair) {
      newInspoArray.push(...choosenHairInspo.filter(i => i.url !== inspo.url));
      setChoosenHairInspo(newInspoArray);
    }
    else {
      newInspoArray.push(...choosenMakeupInspo.filter(i => i.url !== inspo.url));
      setChoosenMakeupInspo(newInspoArray);
    }
  }

  const getCommentDate = (date: Date) => {
    let localDate = toZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
    return `${Utils.getMonthName(localDate.getMonth())} ${localDate.getDate()}`;
  }

  const isEmptyOrSpaces = (str: string | undefined) => {
    if(!str) { return true }
    return str === null || str!.match(/^ *$/) !== null;
  }

  const swap = () => {
    let sr = { value: seasonRight };
    let sl = { value: seasonLeft };
    setSeasonAndOptions(sr, true);
    setSeasonAndOptions(sl, false);
  }

  return (!ticket || !result) ? (<div><Loading loading={true} className=""></Loading></div>) : (
    <div>
      <div className='basicInfoAdmin'>
        <h5>Basics v2:</h5>
        <span>{`Email: ${ticket?.email}`}</span><br/>
        <span>{`Is Legacy Import: ${ticket?.legacyImport ? 'Yes' : 'No'}`}</span><br/>
        <span>{`Nick Name: ${ticket?.nickName}`}</span><br/>
        <span>{`Last Name: ${ticket?.lastName}`}</span><br/>
        <span>{`Status: ${ticket?.status}`}</span><br/>
        <span>{`Wants To Be On TikTok?: ${ticket?.beOnTikTok}`}</span><br/>
        <span>{`Insta: ${ticket?.instagram}`}</span><br/>
        <span>{`TikTok: ${ticket?.tikTok}`}</span><br/>
        <span>{`Status: ${ticket?.status}`}</span><br/>
        <span>{`LastStatusChange: ${getCommentDate(ticket?.lastStatusChangeDate)}`}</span><br/>
        <span>{`Request Info: ${ticket?.initialRequest}`}</span><br/>
        <Button disabled={loading} className="needsMoreInfo" onClick={(e) => navigate('/requests/' + email + '/view')}>Go To Ticket</Button>
      </div>
      <br/>
      { ticket?.comments && (<div className="commentContainer">
        <div>
          <ul>{ticket?.comments.map((r, i) => <li key={i}><b>{r.type === CommentType.Analyzer ? "Kayli" : ticket?.nickName} on {getCommentDate(r.createdDate)}:</b> {r.body}</li>)}</ul>
        </div>
        <form className="commentForm" onSubmit = {addComment}>
          <span>Add Comment: <input className="enterEmail" onChange = {(e) => { setComment({...comment, body: e.target.value}) }} value = {comment?.body}></input></span>
          <button className="sendEmail greenButton" type = 'submit' disabled = {loading || isEmptyOrSpaces(comment?.body)} ><i className="icon bi bi-file-earmark-arrow-up"></i></button>
        </form>
        <Button disabled={loading} className="needsMoreInfo" onClick={needsMoreInfo}>Needs More Info</Button>
      </div> ) }
      <br/>
      <Loading loading={loading} ></Loading>
      
      {((ticket?.mainImageUrl && isEmptyOrSpaces(result?.croppedImageUrl)) || cropping === -1) && (
        <div>
          <h3>Main Image</h3>
          <img onClick={(e) => openCropper(1)} alt="not found" width={"150px"} src={ticket?.mainImageUrl} />
          <br /> <br />
        </div>
      )}
      {((ticket?.mainImageUrlAlt && isEmptyOrSpaces(result?.croppedImageUrl)) || cropping === -1) && (
        <div>
          <h3>Alt Image</h3>
          <img onClick={(e) => openCropper(2)} alt="not found" width={"150px"} src={ticket?.mainImageUrlAlt} />
          <br /> <br />
        </div>
      )}
      { (!isEmptyOrSpaces(result?.croppedImageUrl) ) && (
        <div>
          <h3>Cropped Image</h3>
          <img onClick={(e) => openCropper(3)} alt="not found" width={"150px"} src={result?.croppedImageUrl} />
          <br /> <br />
        </div>
      )}
    
      <br></br>
      
      {cropping > 0 && (
        <div>
          <div style={{width:'100%', height:'400px'}}>
            <ImageEditorComponent ref={(img) => { editingImage = img; }} created={imageEditorReady} shapeChange={shapeChange} selectionChanging={logCropper}
              ></ImageEditorComponent>
          </div>
          <div className='cropPreview' >
            <ColorFramedPortrait viewPortHeight={result!.croppedImageHeightPixels} viewPortWidth={result!.croppedImageWidthPixels}
              hrefBase={croppedImageUrl} hrefCover='../../ClearSpring-12Colors-v1.svg' path={path} />
          </div>
          <Loading loading={loading} ></Loading>
          <br/><br/>
          <Button className="croppingButton" disabled={loading} onClick={showFaceSelector}>Show Face Selector</Button>
          <br/>
          <Button className="croppingButton" disabled={loading} onClick={autoCrop}>Auto Crop (and Save)</Button> (no straightening)
          <br/>
          <Button className="croppingButton" disabled={loading} onClick={onCropperSave}>Save Crop</Button> (straightening)
          <br/>
          <Button className="croppingButton" disabled={loading || !result.croppedImageUrl} onClick={onSelectionSave}>Finish</Button>
          <br/>
          
        </div>
      )}
      <br></br>

      {result?.cropPath && (
        <div>
          <div className="centerIt">
            <button className="croppingButton" disabled={loading} onClick={(e) => { swap(); }}>Swap</button>
          </div>
          <div className="comparisonContainer">
            <div className="comparisonHalf">
              <div className="comparisonFrame">
                <ColorFramedPortrait viewPortHeight={result!.croppedImageHeightPixels} viewPortWidth={result!.croppedImageWidthPixels}
                  svgClass="svgLeft" hrefBase={(result?.croppedImageUrl || ticket?.mainImageUrl) || '' } hrefCover={colorFrameLeft.relativeUrl} path={path} />
              </div>
              <Dropdown className="compareDropDown" value={seasonLeft} options={seasonsForDropDown} onChange={(value: any) => {setSeasonAndOptions(value, true)}} />
              <div className="frameTilesContainer">
                {colorFrameOptionsLeft.map((f, i) => (
                  <div className="frameTile" key={i}>
                    <img onClick={(e) => setColorFrameLeft(f)} alt="!!!" width={"30px"} src={f.relativeUrl} />   
                  </div>
                ))}
              </div>
            </div>
          

            <div className="comparisonHalf">
              <div className="comparisonFrame">
                <ColorFramedPortrait viewPortHeight={result!.croppedImageHeightPixels} viewPortWidth={result!.croppedImageWidthPixels}
                  svgClass="svgRight" hrefBase={(result?.croppedImageUrl || ticket?.mainImageUrl) || '' } hrefCover={colorFrameRight.relativeUrl} path={path} />
              </div>
              <Dropdown className="compareDropDown" value={seasonRight} options={seasonsForDropDown} onChange={(event: any) => {setSeasonAndOptions(event, false)}} />
              <div className="frameTilesContainer">
                {colorFrameOptionsRight.map((f, i) => (
                  <div className="frameTile" key={i}>
                    <img onClick={(e) => setColorFrameRight(f)} alt="!!!" width={"30px"} src={f.relativeUrl} />   
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <br></br>
      <h3>Context Photos</h3>
      <div className="centerIt">
        <img className="selectedContextPhoto" onClick={(e) => { }} alt="!!!" width={"95%"} src={selectedContextPhoto} />
        <button className="croppingButton" disabled={loading || !selectedContextPhoto} onClick={(e) => openCropper(selectedContextPhotoNumber)}>Open This Image in Cropper</button>
        <div className="legacyPhotosContainer centerIt">
          {ticket.requestImageUrls && (<div className="legacyPhotosSubContainer">
            {ticket.requestImageUrls.map((url, i) => (
              <div className="contextPhotoTile" style={{ borderColor: url === selectedContextPhoto ? 'cyan': 'darkgray'}} key={i}>
                <img  onClick={(e) => {setSelectedContextPhoto(url); setSelectedContextPhotoNumber(i+4);}} alt="!!!" width={"80px"} src={url} />   
              </div>
            ))}
          </div>)}
        </div>
      </div>
      <br></br>

      <br />

      { errorMessage && <h2> {errorMessage} </h2> }

      <h3>Color Season Result</h3>
      <Dropdown disabled={loading} value={result?.result} options={seasonsForDropDown} onChange={(event: any) => decideResult(event.value)} />
      <br/>
      <Loading loading={loading} ></Loading>
      <h4>Introduction</h4>
      <div>
        <textarea className="textarea" value={introduction} onChange={e => setIntroductionAndResult(e.target.value) } />
        {relevantIntroBlurbs && relevantIntroBlurbs.map((b, i) => (
          <div className="blurbThumbnail" key={i} onClick={e => setIntroductionAndResult(b.content) }>
            {b.subType}
          </div>
        ))}
        <div className="blurbThumbnail" onClick={e => saveNewBlurb(BlurbType.Introduction, newBlurbName, introduction) }>
          Save As New Intro Blurb
        </div>
        <span>New Blurb Name: <input onChange = {(e) => setNewBlurbName(e.target.value)} value = {newBlurbName}></input></span>
      </div>
      <br/>

      <h4>Hair Explanation</h4>
      <div>
        <textarea className="textarea" value={hairExplanation} onChange={e => setHairAndResult(e.target.value) } />
        {relevantHairBlurbs && relevantHairBlurbs.map((b, i) => (
          <div className="blurbThumbnail" key={i} onClick={e => setHairAndResult(b.content) }>
            {b.subType}
          </div>
        ))}
        <div className="blurbThumbnail" onClick={e => saveNewBlurb(BlurbType.Hair, newHairBlurbName, hairExplanation) }>
          Save As New Hair Blurb
        </div>
        <span>New Hair Blurb Name: <input onChange = {(e) => setNewHairBlurbName(e.target.value)} value = {newHairBlurbName}></input></span>
      </div>
      <br/>

      <h4>Makeup Explanation</h4>
      <div>
        <textarea className="textarea" value={makeupExplanation} onChange={e => setMakeupAndResult(e.target.value) } />
        {relevantMakeupBlurbs && relevantMakeupBlurbs.map((b, i) => (
          <div className="blurbThumbnail" key={i} onClick={e => setMakeupAndResult(b.content) }>
            {b.subType}
          </div>
        ))}
        <div className="blurbThumbnail" onClick={e => saveNewBlurb(BlurbType.Makeup, newMakeupBlurbName, makeupExplanation) }>
          Save As New Makeup Blurb
        </div>
        <span>New Makeup Blurb Name: <input onChange = {(e) => setNewMakeupBlurbName(e.target.value)} value = {newMakeupBlurbName}></input></span>
      </div>
      <br/>

      <h4>Hair Inspo</h4>
      {choosenHairInspo && choosenHairInspo.map((b, i) => (
        <img key={i} onClick={(e) => deselectInspo(b, i) } alt="!!!" width={"150px"} src={b.url} />   
      ))}
      <br/>
      <div className="inspoContainer">
        {relevantHairInspo && relevantHairInspo.map((b, i) => (
            <div className="inspoUnit" key={i}>
              <img className="inspoPic" key={i} onClick={(e) => selectInspo(b) } alt="!!!" width={"50px"} src={b.url} />  
              <div className="centerIt">
              </div>
            </div>
        ))}
      </div>

      <br/>
      <h4>Makeup Inspo</h4>
      {choosenMakeupInspo && choosenMakeupInspo.map((b, i) => (
        <img key={i} onClick={(e) => deselectInspo(b, i) } alt="!!!" width={"150px"} src={b.url} />   
      ))}
      <br/>
      <div className="inspoContainer">
        {relevantMakeupInspo && relevantMakeupInspo.map((b, i) => (
            <div className="inspoUnit">
              <img className="inspoPic" key={i} onClick={(e) => selectInspo(b) } alt="!!!" width={"50px"} src={b.url} />  
            </div>
        ))}
      </div>


      <br/>
      <span>Upload Hair Inspos for {result.result}
        <input
              type="file"
              name="myImage"
              className="contextButton"
              multiple
              disabled={loading}
              onChange={(event: any) => {
                uploadInspoImage(event, InspoImageType.Hair);
              }}
            />
      </span>
      <br/>

      <span>Upload Makeup Inspos for {result.result}
        <input
              type="file"
              name="myImage"
              className="contextButton"
              multiple
              disabled={loading}
              onChange={(event: any) => {
                uploadInspoImage(event, InspoImageType.Makeup);
              }}
            />
      </span>

      <br/>
      <h4>Sister Seasons</h4>
      <Select
          defaultValue={selectedSisterSeasons?.map((s) => { return { value: s, label: s } } )}
          value={selectedSisterSeasons?.map((s) => { return { value: s, label: s } } )}
          isMulti
          options={seasonsForMultiDropDown}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={ (values) => {
            setSelectedSisterSeasons(values?.map(v => {return v!.value} ) );
            setNoBlurbSisterSeasons(values?.map(v => {return v!.value} ).filter((s) => !allBlurbs.find((b) => b.primarySeason === result.result && b.type === BlurbType.SisterSeasons && b.otherSeason === s)))
           } }
        />
      {noBlurbSisterSeasons && noBlurbSisterSeasons.map((s, i) => (
        <div className="missingBlurb">
          <i className={"icon bi bi-x-octagon badPicIcon"}></i>
          {s} MISSING BLURB!
        </div>
      ))}

      <br/>
      <h4>Opposite Seasons</h4>
      <Select
          defaultValue={selectedOppositeSeasons?.map((s) => { return { value: s, label: s } } )}
          value={selectedOppositeSeasons?.map((s) => { return { value: s, label: s } } )}
          isMulti
          options={seasonsForMultiDropDown}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={ (values) => {
            setSelectedOppositeSeasons(values?.map(v => {return v!.value} ) );
            setNoBlurbOppositeSeasons(values?.map(v => {return v!.value} ).filter((s) => !allBlurbs.find((b) => b.primarySeason === result.result && b.type === BlurbType.OppositeSeasons && b.otherSeason === s)))
           } }
        />
      {noBlurbOppositeSeasons && noBlurbOppositeSeasons.map((s, i) => (
        <div className="missingBlurb">
          <i className={"icon bi bi-x-octagon badPicIcon"}></i>
          {s} MISSING BLURB!
        </div>
      ))}
      
      <br/><br/>
      <Loading loading={loading} ></Loading>
      <button className="croppingButton" disabled={loading} onClick={(e) => updateResult(result)}>Save Progress</button>
      <br/>
      <button className="croppingButton" disabled={!readyToSubmit() || loading} onClick={e => navigate('/requests/' + email + '/results')}>Preview Results</button>
      <br/>
      <button className="croppingButton" onClick={submitTicket} disabled={!readyToSubmit() || loading} >Send Color Analysis Results!</button>
      <br/>
      <Footer></Footer>
    </div>
  );
};

export default AdminRequestManage;
